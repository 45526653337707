import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';
import { graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';

import SEO from 'components/shared/SEO';
import Popup from 'components/shared/Popup';
import Header from 'components/Header/Header';
import Contact from 'components/Contact/Contact';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';
// import Cookies from 'components/Cookies/Cookies';
import Announcment from 'components/Announcment/Announcment';
import Details from 'components/Details';
// import Details2 from 'components/Details2';
import Location from 'components/Location';
import Gallery from 'components/Gallery/Gallery';
import Credit from 'components/Credit/Credit';
import About from 'components/About';
import AskAboutFlat from 'components/AskAboutFlat/AskAboutFlat';
import Timeline from 'components/Timeline';
import Browser from 'browser';
import { ButtonPromotion } from 'components/ButtonPromotion';

import { getFlatsFromAPI, parseFlat } from 'utils/getFlatsFromApi';
import manIcon from 'assets/icons/man.svg';
import womanIcon from 'assets/icons/woman.svg';

import ScrollableSection from 'react-update-url-on-scroll';

const isSSR = typeof window === 'undefined';

const LazyPromotionDrawer = React.lazy(() =>
  import('components/PromotionDrawer')
);

const IndexPage = ({ data }) => {
  const { initialSearchParam } = useContext(NavigationContext);

  const HOMEPAGE = data.wpgraphql.page.stronaGlowna;
  const popupImg = HOMEPAGE.promoPopup && HOMEPAGE.promoPopup.sourceUrl;

  const promoPdf = {
    pdfText: HOMEPAGE.promoPdfText,
    pdfLink: HOMEPAGE.promoPdfFile.link,
  };

  const creditImg = data.creditImg.childImageSharp.fluid;
  const creditMobileImg = data.creditMobileImg.childImageSharp.fluid;
  const hero1Img = data.hero1Img.childImageSharp.fluid;
  const heroMobile = data.heroMobile.childImageSharp.fluid;
  const locationBG = data.locationBG.childImageSharp.fluid;
  const locationMobileBG = data.locationMobileBG.childImageSharp.fluid;

  const galleryAlbums = useMemo(
    () =>
      HOMEPAGE.galleryAlbums.map(
        ({ galleryAlbumsTitle, galleryAlbumsImages }) => ({
          title: galleryAlbumsTitle,
          images: galleryAlbumsImages.map(({ galleryAlbumsImagesImg }) => ({
            src: galleryAlbumsImagesImg.imageFile.childImageSharp.fluid,
            alt: `${galleryAlbumsImagesImg.title}`,
          })),
        })
      ),
    []
  );

  const creditTeam = useMemo(
    () =>
      HOMEPAGE.creditTeam.map(
        ({
          creditTeamEmail,
          creditTeamGender,
          creditTeamName,
          creditTeamPhone,
          creditTeamRole,
        }) => ({
          icon: creditTeamGender === 'man' ? manIcon : womanIcon,
          name: creditTeamName,
          job: creditTeamRole,
          phone: creditTeamPhone,
          email: creditTeamEmail,
        })
      ),
    []
  );

  const creditOpeningHours = HOMEPAGE?.creditOpeningHours?.map(item => ({
    from: item?.creditOpeningHoursOpened,
    to: item?.creditOpeningHoursClosed,
    day: item?.creditOpeningHoursDay,
  }));

  const openingHoursGrouped =
    creditOpeningHours?.reduce((acc, curr) => {
      const { from, to, day } = curr;

      const existing = acc[from];

      if (existing) {
        existing.push({ from, to, day });
      } else {
        acc[from] = [{ from, to, day }];
      }

      return acc;
    }, {}) ?? [];

  const CREDIT_OPENING_HOURS =
    openingHoursGrouped &&
    Object.values(openingHoursGrouped).map(item => {
      let dayLabel = '';

      item.forEach((it, index) => {
        const isFirst = index === 0;
        const isLast = index === item.length - 1;

        if (isFirst) {
          dayLabel = it.day;
        }

        if (!isFirst) {
          dayLabel = `${dayLabel}, ${it.day}`;
        }

        if (isLast) {
          dayLabel = `${dayLabel}: godz. ${it.from} - ${it.to}`;
        }
      });

      return dayLabel;
    });

  const openingHours = useMemo(
    () =>
      HOMEPAGE.contactHours.map(
        ({ contactHoursName, contactHoursFrom, contactHoursTo }) => ({
          day: contactHoursName,
          openingTime: contactHoursFrom,
          closingTime: contactHoursTo,
        })
      ),
    []
  );

  const timelines = useMemo(
    () =>
      HOMEPAGE?.aboutStages
        ?.map(({ aboutStagesHeading, aboutStagesDates }) => ({
          title: aboutStagesHeading,
          background: data.timelineBg.childImageSharp.fixed,
          dates: (aboutStagesDates || []).map(
            ({ aboutStagesDatesDate }) => aboutStagesDatesDate
          ),
        }))
        .filter(({ dates }) => dates.length > 0),
    []
  );

  const galleryImages = useMemo(
    () =>
      HOMEPAGE.locGallery.map(({ locGalleryItemText, locGalleryItemImg }) => ({
        src: locGalleryItemImg.imageFile.childImageSharp.fluid,
        text: locGalleryItemText,
      })),
    []
  );

  const FlatsScrollAnchor = styled.span`
    position: absolute;
    transform: translateY(-45vh);
  `;

  const [promoFlats, setPromoFlats] = React.useState([]);

  React.useEffect(() => {
    const getFlats = getFlatsFromAPI(
      'https://api.matexipolska.pl/export/api_getproducts/?key=585ee263d5148ce97aa833f48724be1f&mode=json&ExtraData=1&ShowAll=1&ID_Investment=40',
      parseFlat
    );
    getFlats()
      .then(flats => {
        const filteredFlats = flats.filter(
          flat => flat.status !== 3 && flat.isPromotion
        );
        setPromoFlats(filteredFlats);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    typeof window !== 'undefined' && (
      <>
        {popupImg && <Popup popupImg={popupImg} />}
        {!isSSR && promoFlats.length > 0 && (
          <>
            <ButtonPromotion count={promoFlats.length} />
            <React.Suspense fallback={<></>}>
              <LazyPromotionDrawer promotionFlats={promoFlats} />
            </React.Suspense>
          </>
        )}
        <Navigation />
        <SEO title="Strona główna" />
        <LazyLoad height="125vh" offset={50000} once>
          <ScrollableSection name={`${initialSearchParam}#o-inwestycji `}>
            <Header image1={hero1Img} imageMobile={heroMobile} />
            <AskAboutFlat />
          </ScrollableSection>
        </LazyLoad>
        <main>
          <LazyLoad height="125vh" offset={50000} once>
            <About
              heading={HOMEPAGE.aboutHeading}
              description={HOMEPAGE.aboutDesc}
              prospect={HOMEPAGE.aboutProspect}
              image={HOMEPAGE.aboutImage.imageFile.childImageSharp.fluid}
            />
          </LazyLoad>
          {timelines?.map((timeline, index) => (
            <Timeline key={index} timeline={timeline} />
          ))}
          <span id="lokalizacja" />
          <LazyLoad height="130vh" offset={50000} once>
            <Location
              background={locationBG}
              backgroundMobile={locationMobileBG}
              gallery={galleryImages}
              heading={HOMEPAGE.locHeading}
              content={HOMEPAGE.locText}
              lat={Number(HOMEPAGE.locMapLat)}
              lng={Number(HOMEPAGE.locMapLng)}
              locations={HOMEPAGE.locMapLocations || []}
            />
          </LazyLoad>

          <LazyLoad height="125vh" offset={50000} once>
            <ScrollableSection name={`${initialSearchParam}#mieszkania`}>
              <span id="mieszkania" />
              <Browser />
            </ScrollableSection>
          </LazyLoad>
          <FlatsScrollAnchor id="mieszkania-scroll-anchor" />
          <span id="kredyt" />
          <LazyLoad height="130vh" offset={50000} once>
            <Credit
              heading={HOMEPAGE.creditHeading}
              description={HOMEPAGE.creditText}
              teamHeading={HOMEPAGE.creditTitle}
              image={creditImg}
              imageMobile={creditMobileImg}
              team={creditTeam}
              text1={HOMEPAGE.creditText2}
              text2={HOMEPAGE.creditAddendum}
              openingHoursLabels={CREDIT_OPENING_HOURS}
            />
          </LazyLoad>
          <span id="wykonczenia" />
          <LazyLoad height="130vh" offset={50000} once>
            <Details
              image={HOMEPAGE.detailsImage.imageFile.childImageSharp.fluid}
              logo={HOMEPAGE.detailsLogo.imageFile.childImageSharp.fluid}
              description={HOMEPAGE.detailsText}
              text1={HOMEPAGE.detailsText2}
              text2={HOMEPAGE.detailsText3}
              phone={HOMEPAGE.detailsPhone}
              packets={HOMEPAGE.detailsOptions.map(
                ({ detailsOptionText, detailsOptionPdf }) => ({
                  name: detailsOptionText,
                  pdf: detailsOptionPdf.mediaItemUrl,
                })
              )}
            />
          </LazyLoad>
          {/* <span id="wykonczenia-2" />
          <LazyLoad height="100vh" offset={50000} once>
            <Details2
              image={HOMEPAGE?.details2Image?.imageFile?.childImageSharp?.fluid}
              imageAlt={HOMEPAGE?.details2Image?.altText}
              logo={HOMEPAGE?.details2Logo?.imageFile?.childImageSharp?.fluid}
              logoAlt={HOMEPAGE?.details2Logo?.altText}
              heading={HOMEPAGE?.details2Title}
              description={HOMEPAGE?.details2Description}
              basicBenefitsList={
                HOMEPAGE?.details2BasicBenefits?.map(
                  item => item?.details2BasicBenefitsSingle
                ) ?? []
              }
              additionalBenefitsList={
                HOMEPAGE?.details2AdditionalBenefits?.map(
                  item => item?.details2AdditionalBenefitsSingle
                ) ?? []
              }
              team={
                HOMEPAGE?.details2Tutors?.map(item => ({
                  name: item?.details2TutorsName,
                  job: item?.details2TutorsPosition,
                  phone: item?.details2TutorsPhoneNumber,
                  email: item?.details2TutorsEmail,
                })) ?? []
              }
            />
          </LazyLoad> */}
          <span id="galeria" />
          <LazyLoad height="130vh" offset={50000} once>
            <Gallery
              heading={HOMEPAGE.galleryHeading}
              image={HOMEPAGE.galleryBg.imageFile.childImageSharp.fluid}
              albums={galleryAlbums}
            />
          </LazyLoad>
          <span id="kontakt" />
          <LazyLoad height="100vh" offset={50000} once>
            <Contact
              title={HOMEPAGE.contactHeading}
              address={HOMEPAGE.contactAddress}
              phone={HOMEPAGE.contactPhone}
              mapCords={{
                lat: Number(HOMEPAGE.contactMapLat),
                lng: Number(HOMEPAGE.contactMapLng),
              }}
              infos={
                HOMEPAGE.contactInfo
                  ? HOMEPAGE.contactInfo.map(
                      ({ contactInfoInfo }) => contactInfoInfo
                    )
                  : ['']
              }
              promoPdf={promoPdf}
              openingHours={openingHours}
            />
          </LazyLoad>
          <LazyLoad height="130vh" offset={50000} once>
            <Announcment
              heading={HOMEPAGE.announcementHeading}
              description={HOMEPAGE.announcementContent}
              image={HOMEPAGE.announcementBg.imageFile.childImageSharp.fluid}
              youtubeLink={HOMEPAGE.announcementYoutube}
              videoURL={HOMEPAGE.announcementVideo}
            />
          </LazyLoad>
        </main>
        <LazyLoad height={150} offset={500} once>
          <Footer />
        </LazyLoad>
        {/* <Cookies /> */}
      </>
    )
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePage {
    hero1Img: file(name: { eq: "hero-1" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    announcmentImg: file(name: { eq: "background" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMobile: file(name: { eq: "hero-mobile" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    creditImg: file(name: { eq: "credit" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    creditMobileImg: file(name: { eq: "credit-mobile" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    locationBG: file(name: { eq: "location" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    locationMobileBG: file(name: { eq: "location-mobile" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    timelineBg: file(name: { eq: "timeline" }) {
      childImageSharp {
        fixed(width: 1200, height: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    wpgraphql {
      page(id: "strona-glowna-lp", idType: URI) {
        stronaGlowna {
          promoPdfText
          promoPdfFile {
            link
          }
          promoPopup {
            sourceUrl
          }
          aboutHeading
          aboutDesc
          aboutStages {
            aboutStagesHeading
            aboutStagesDates {
              aboutStagesDatesDate
            }
          }
          aboutImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutProspect {
            aboutProspectTitle
            aboutProspectText
            aboutProspectAttachment {
              link
            }
          }
          creditTitle
          creditText
          creditHeading
          creditTeam {
            creditTeamEmail
            creditTeamGender
            creditTeamName
            creditTeamPhone
            creditTeamRole
            fieldGroupName
          }
          creditText2
          creditAddendum
          creditOpeningHours {
            creditOpeningHoursClosed
            creditOpeningHoursDay
            creditOpeningHoursOpened
          }
          contactMapLat
          contactMapLng
          contactInfo {
            contactInfoInfo
          }
          locHeading
          locText
          locMapLat
          locMapLng
          locGallery {
            locGalleryItemText
            locGalleryItemImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          locMapLocations {
            locMapLocationLat
            locMapLocationLng
            locMapLocationName
            locMapLocationType
          }
          galleryHeading
          galleryAlbums {
            galleryAlbumsTitle
            galleryAlbumsImages {
              galleryAlbumsImagesImg {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          galleryBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailsText
          detailsPhone
          detailsImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailsOptions {
            detailsOptionText
            detailsOptionPdf {
              mediaItemUrl
            }
          }
          detailsLogo {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailsPhone
          detailsText2
          detailsText3
          details2Title
          details2Description
          details2BasicBenefits {
            details2BasicBenefitsSingle
          }
          details2AdditionalBenefits {
            details2AdditionalBenefitsSingle
          }
          details2Tutors {
            details2TutorsEmail
            details2TutorsName
            details2TutorsPhoneNumber
            details2TutorsPosition
          }
          details2Image {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          details2Logo {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          contactPhone
          contactHeading
          contactAddress
          contactHours {
            contactHoursFrom
            contactHoursName
            contactHoursTo
          }
          announcementContent
          announcementHeading
          announcementVideo
          announcementYoutube
          announcementBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
